<template>
  <div
    @click="onClickBtn"
    class="button-widget-container"
    ref="textContent"
    :style="setStyles"
  >
    <div class="text-container" :style="textStyles">
      {{ widget && widget.objects && widget.objects[1].text }}
    </div>
  </div>
</template>

<script>
import fetchFont from "@/utils/fetchFont";
import setStyles from "@/utils/setStyles";
import clickAction from "@/utils/clickAction";
import { mapGetters } from "vuex";

export default {
  name: "SvButtonWidget",
  props: ["widgetIdx"],
  data() {
    return {
      btnDisabled: false
    };
  },
  computed: {
    ...mapGetters([
      "interaction",
      "step",
      "respond",
      "interactionId",
      "respondId",
      "activeStep",
    ]),
    widget() {
      return this.activeStep?.objects?.[this.widgetIdx]
    },
    textItem() {
      if (!this.widget) return;
      return this.widget.objects[1];
    },
    setStyles() {
      return {
        ...setStyles(this.widget),
        background: this.widget.objects[0].fill,
        cursor: this.widget.configs.activeType !== "none" ? "pointer" : "",
        borderRadius: this.widget.objects[0].rx + "px"
      };
    },
    textStyles() {
      this.nameItem(this.textItem, "text");
      return setStyles(this.textItem, { setGeneral: false });
    }
  },
  mounted() {
    const fontFamily = this.textItem.fontFamily.replace(/\.(ttf|otf)/g, "");
    const fontLink = this.widget.configs.fontLink
    if (fontFamily)
      fetchFont(fontLink, fontFamily).then(() => {
        this.$refs.textContent.style.fontFamily = fontFamily;
        this.$emit("hideLoader");
      });
  },
  methods: {
    nameItem(item, name) {
      item.name = name;
    },
    onClickBtn() {
      if (this.btnDisabled) return;
      this.btnDisabled = true;
      clickAction({
        widget: this.widget,
        widgetName: this.textItem.text,
        widgetIdx: this.widgetIdx
      }).then(() => {
        this.btnDisabled = false;
      });
    }
  }
};
</script>

<style scoped>
.button-widget-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.text-container {
  text-align: center;
}
</style>
